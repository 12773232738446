@import url('https://fonts.googleapis.com/css?family=Karla:400,700');

$green: #43BF4B;
$dark-green: #395B3B;
$body-color: #1F2A20;
$white: #FFFFFF;
$grey: #F2F2F2;

$fontsans: 'Karla', sans-serif;
$regular: 400;
$bold: 700;

// RESPONSIVE

$mobile:       ( max:  767px );
$tablet:       ( min:  768px );
$laptop:       ( min:  992px );
$desktop:      ( min: 1200px );
$tv:           ( min: 1640px );

@mixin breakpoint($map) {
  $query: "";
  @if map-has-key($map, min) { $query: append($query, "(min-width: #{map-get($map, min)})") }
  @if map-has-key($map, min) and map-has-key($map, max) { $query: append($query, "and") }
  @if map-has-key($map, max) { $query: append($query, "(max-width: #{map-get($map, max)})") }
  @media screen and #{$query} { @content; }
}