.footer {
  background: $body-color;
  padding: 30px 0;
  img {
    height: 60px;
    margin-bottom: 20px;
  }
  small {
    display: block;
    color: $white;
    font-size: 12px;
    text-align: center;
    padding-top: 5px;
  }
  .flex-center {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}