h1 {
  font-size: 40px;
  font-weight: $bold;
  color: $dark-green;
  @include breakpoint($mobile){
    font-size: 30px;
  }
}

p {
  font-size: 18px;
  line-height: 27px;
  @include breakpoint($mobile){
    font-size: 16px;
    line-height: 24px;
  }
}

a {
  color: $green;
  text-decoration: none !important;
  &:hover {
    color: darken($green, 15%);
  }
}