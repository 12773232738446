section {
  padding: 130px 0;
  @include breakpoint($mobile){
    padding: 80px 0;
  }
}

.mission {
  height: 100vh;
  width: 100%;
  position: relative;
  padding: 0 !important;
  @include breakpoint($mobile){
    height: 500px;
  }
  .container {
    height: 100%;
  }
  &-content {
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 10;
    h1 {
      display: flex;
      flex-direction: column;
      font-weight: $bold;
      color: $white;
      position: relative;
      span:first-child {
        @include breakpoint($mobile){
          font-size: 30px;
        }
        @include breakpoint($tablet){
          font-size: 30px;
        }
        @include breakpoint($laptop){
          font-size: 64px;
        }
      }
      span:nth-child(2){
        padding-top: 20px;
        @include breakpoint($mobile){
          font-size: 20px;
        }
        @include breakpoint($tablet){
          font-size: 20px;
        }
        @include breakpoint($laptop){
          font-size: 48px;
        }
      }
    }
  }
  &-swiper-container {
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    &:after {
      content: " ";
      position: absolute;
      top: 0; left: 0; right: 0; bottom: 0;
      width: 100%;
      height: 100%;
      background: -moz-linear-gradient(45deg, rgba(47,61,39,1) 0%, rgba(105,166,70,0.4) 60%, rgba(135,224,253,0) 100%);
      background: -webkit-linear-gradient(45deg, rgba(47,61,39,1) 0%,rgba(105,166,70,0.4) 60%,rgba(135,224,253,0) 100%);
      background: linear-gradient(45deg, rgba(47,61,39,1) 0%,rgba(105,166,70,0.4) 60%,rgba(135,224,253,0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2f3d27', endColorstr='#0087e0fd',GradientType=1 );
      z-index: 1;
      opacity: .6;
    }
    .swiper-wrapper {
      .swiper-slide {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

// PRESENTATION

.presentation {
  background: $grey;
  .picture-container {
    margin-top: 50px;
    width: 100%;
    height: 600px;
    img {
      width: 100%; height: 100%;
      object-fit: cover;
    }
  }
  p {
    padding-top: 90px;
    @include breakpoint($mobile){
      padding-top: 30px;
    }
  }
  .btn {
    margin-top: 30px;
  }
}

// SERVICES

.services {
  h1 {
    text-align: center;
    margin-bottom: 100px;
  }
  .flex-center {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 100px;
  }
  &-list {
    margin-top: 30px;
    li {
      font-size: 20px;
      line-height: 25px;
      color: $dark-green;
      margin-bottom: 20px;
      font-weight: $bold;
      &:last-child{
        margin-bottom: 0;
      }
      @include breakpoint($mobile){
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 10px;
      }
    }
  }
  .btn {
    @include breakpoint($mobile){
      margin-top: 50px;
    }
    @include breakpoint($tablet){
      margin-top: 50px;
    }
    @include breakpoint($laptop){
      margin-top: 0;
    }
  }
}

// REALISATION

.creation {
  background: $grey;
  position: relative;
  &:before {
    content: " ";
    position: absolute;
    top: 0; right: 0; left: 0;
    width: 100%;
    height: 400px;
    background: $white;
    z-index: 0;
  }
  &-swiper-container {
    width: 100%;
    height: 550px;
    overflow: hidden;
    @include breakpoint($mobile){
      height: 350px;
    }
    .swiper-slide {
      width: 100%;
      height: 100%;
      position: relative;
      &:before {
        content: " ";
        position: absolute;
        right: 0; bottom: 0; left: 0;
        width: 100%;
        height: 20%;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
        background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
        z-index: 5;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0; left: 0; right: 0; bottom: 0;
        z-index: 0;
      }
      h2 {
        position: absolute;
        bottom: 40px; left: 50px;
        font-size: 16px;
        line-height: 24Px;
        z-index: 10;
        color: $white;
        @include breakpoint($mobile){
          left: 20px;
          margin-right: 20px;
        }
      }
    }
  }
  h1 {
    margin-top: 50px;
  }
  .btn-container {
    margin-top: 45px;
    display: flex;
    width: 80%;
    justify-content: space-between;
    @include breakpoint($mobile){
      width: 100%;
    }
  }
  .creation-swiper-button-prev, .creation-swiper-button-next {
    width: 50px;
    height: 50px;
    background: #E1E1E1;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background .2s ease;
    svg {
      * {
        fill: #767676;
        transition: fill .2s ease;
      }
    }
    &:hover {
      background: $green;
      svg {
        * {
          fill: $white;
        }
      }
    }
  }
}

// CONTACT

.contact {
  background: $grey;
  padding-top: 0;
  .container {
    border-top: 1px solid #C1C1C1;
    padding-top: 130px;
  }
  h1 {
    text-align: center;
  }
  small {
    text-align: center;
    margin-top: 15px;
    display: block;
    margin-bottom: 60px;
  }
  &-form {
    .form-element-container {
      margin-bottom: 30px;
      .form-label {
        font-size: 14px;
        font-weight: $bold;
        text-transform: uppercase;
        display: block;
        padding-bottom: 10px;
      }
      input[type='text'], input[type='email'], input[type='tel']{
        appearance: none;
        background: $white;
        border: 1px solid #B5B5B5;
        padding: 12px 20px;
        font-size: 16px;
        outline: none;
        border-radius: 3px;
        width: 100%;
        &:hover, &:active, &:focus {
          border-color: $green;
        }
      }
      input[type='checkbox'], input[type='radio']{
        appearance: none;
        background: $white;
        border: 1px solid #B5B5B5;
        width: 20px;
        border-radius: 3px;
        height: 20px;
        outline: none;
        position: relative;
        top: 5px;
        margin-right: 10px;
        &:after {
          content: " ";
          position: absolute;
          width: 8px;
          height: 8px;
          top: 50%; margin-top: -4px;
          left: 50%; margin-left: -4px;
          border-radius: 2px;
          background: $green;
          opacity: 0;
          transition: opacity .2s ease;
        }
        &:hover, &:active, &:focus {
          border-color: $green;
        }
        &:checked {
          &:after {
            opacity: 1;
          }
        }
      }
      textarea {
        width: 100%;
        height: 200px;
        padding: 20px;
        outline: none;
        resize: vertical;
        max-height: 300px;
        min-height: 100px;
        border: 1px solid #B5B5B5;
        border-radius: 3px;
        &:hover, &:active, &:focus {
          border-color: $green;
        }
      }
    }
  }
  h2 {
    font-size: 25px;
    margin: 40px 0 30px 0;
  }
  &-infos {
    font-size: 16px;
    color: $body-color;
    li {
      margin-bottom: 7px;
    }
  }
}