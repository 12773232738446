.btn {
  font-size: 18px;
  color: $white !important;
  background: $green;
  padding: 12px 20px 13px 20px;
  display: inline-block;
  border-radius: 3Px;
  border: 1px solid #259B47;
  box-shadow: 0px 2px 0px 0px rgba(27,156,67,1);
  text-shadow: 0px 1px rgba(000,000,000,.2);
  text-decoration: none !important;
  transform: translateY(0);
  transition: transform .2s ease, box-shadow .2s ease;
  &:hover {
    transform: translateY(2px);
    box-shadow: 0px 0px 0px 0px rgba(27,156,67,1);
  }
}