.header {
  position: fixed;
  width: 100%;
  z-index: 100;
  @include breakpoint($mobile){
    position: absolute;
  }
  .container {
  }
  &-content {
    width: 100%;
    height: 80px;
    background: white;
    margin-top: 30px;
    border-radius: 3px;
    box-shadow: 0px 16px 20px 0px rgba(0,0,0,0.14);
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include breakpoint($mobile){
      justify-content: center;
    }
    .logo {
      margin-left: 30px;
      padding-right: 30px;
      border-right: 1px solid #CCCCCC;
      @include breakpoint($mobile){
        border-right: 0;
      }
      img {
        height: 50px;
      }
    }
    .split {
      display: flex;
      align-items: center;
    }
    nav {
      @include breakpoint($mobile){
        display: none;
      }
      ul {
        display: flex;
        li {
          a {
            padding: 0 30px;
            font-size: 18px;
            color: $body-color;
            text-decoration: none;
            position: relative;
            transition: color .2s ease;
            &:after {
              content: "";
              position: absolute;
              bottom: -29px; left: 0; right: 0;
              width: 100%;
              height: 3px;
              background: $green;
              opacity: 0;
              transition: opacity .2s ease;
            }
            &:hover {
              color: $green;
              &:after {
                opacity: 1;
              }
            }
          }
        }
      }
    }
    .btn {
      margin-right: 30Px;
      @include breakpoint($mobile){
        display: none;
      }
      @include breakpoint($tablet){
        display: none;
      }
      @include breakpoint($laptop){
        display: block;
      }
    }
  }
}